import React, { Fragment, useEffect, useState } from "react";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { Icon } from "@mdi/react";
import { mdiChevronLeft, mdiInformation } from "@mdi/js";
import moment from "moment";
import { Table, UncontrolledTooltip } from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import { DisappearedLoading } from "react-loadingg";
import { ChevronLeft, ChevronRight } from "react-feather";
import "./affiliateStyle.scss"

const AffiliateStores = (props) => {

  const userData = props.merchant_user
  const merchantData = props.merchant_info

  const [company, setCompany] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [countries, setCountries] = useState([]);
  const [referrStore, setReferrStore] = useState([]);
  const [currency1RateValue, setcurrency1Rate] = useState(1);
  const [currency2RateValue, setcurrency2Rate] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [data, setData] = useState([]);
  const [btn_active_status, setBtn_active_status] = useState("all");
  const [loading, setLoading] = useState(true);
  const [arrows, setArrows] = useState({ left: false, right: true });
  const [referralCodeCompany, setReferralCodeCompany] = useState("");
  const [showReferStore, setShowReferStore] = useState(true);
  const [storeInvoices, setStoreInvoices] = useState([]);
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyCode1, setCurrencyCode1] = useState("");
  const [currencyCode2, setCurrencyCode2] = useState("");
  const [referralCommission, setReferralCommission] = useState(0);
  const [referralCommissionNewType, setReferralCommissionNewType] = useState(0);

  const [storeName, setStoreName] = useState("");


  useEffect(() => {
    let postdata = { admin_id: props.merchant_user._id }
    client.post(api.fetch_all_stores, postdata, (error, response) => {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              if (response.result.merchants.length == 0) {

              } else {
                localStorage.setItem('@allStoreCount', response.result.merchants.length)
                setCompany(response.result.company);
                setMerchants(response.result.merchants)
              }
            }
          } catch (e) { }
        }
      }
    });
  }, [])

  useEffect(() => {
    client.post(
      api.countries_list,
      { show_all: true },
      async (error, response) => {
        if (!error) {
          try {
            if (!response.error) {
              setCountries(response.result.data);
            }
          } catch (e) { }
        } else {
          // toast.error("Internal error occured.Please contact support");
        }
      }
    );
  }, []);

  let tableData = []

  function showStoreInvoice(invoices) {
    toggleShowReferStore();
  }
  function toggleShowReferStore() {
    setShowReferStore(!showReferStore);
  }

  if (company.length) {
    company.map(comp => {
      merchants.filter(merch => comp._id.trim() == merch.company_id.trim()).map(item => {
        if (userData?._id === item.adminId) {
          let rowData = {
            company_id: comp._id,
            companyName: comp.companyName,
            storeName: item.business_name,
            referralCode: item.my_referral_code,
            email: item.email,
            country: item.country
          }
          tableData.push(rowData)
          // console.log(rowData,"rowData")
          // console.log(tableData,"tableData-inside")
        } else {
          // console.log(comp._id,"compId")
          // console.log(merch.company_id,"merchId")
          // console.log(item.adminId,"itemid")
          // console.log(userData.id,"userdata-id")
        }
      })
    })
  }

  const groupByCompany = (data) => {
    const result = {};
    data.forEach((item) => {
      if (!result[item.company_id]) {
        result[item.company_id] = {
          name: item.companyName,
          stores: [],
        };
      }
      result[item.company_id].stores.push(item);
    });
    return Object.values(result);
  };

  const groupedData = groupByCompany(tableData);

  if (tableData.length && referrStore.length == 0) {
    let postData = btn_active_status != "all" ? [referralCodeCompany] : tableData.map(item => item.referralCode)
    client.post(api.referral_stores, postData, async function (error, response) {
      if (!error) {
        if (!response.error) {
          let arr = response.result.filter(ele => ele.hasOwnProperty("subscription_fees"))
          setReferrStore(arr)
          setLoading(false)
        }
      }
    })
  }

  useEffect(() => {
    if (tableData.length) {
      let postData = btn_active_status != "all" ? [referralCodeCompany] : tableData.map(item => item.referralCode)
      client.post(api.referral_stores, postData, async function (error, response) {
        if (!error) {
          if (!response.error) {
            let arr = response.result.filter(ele => ele.hasOwnProperty("subscription_fees"))
            setReferrStore(arr)
            setLoading(false)
          }
        }
      })
    }
  }, [btn_active_status])

  const changeCurrency = (amount, currency1, currency2, currency1Rate, currency2Rate) => {
    let curr_amount = amount;

    if (curr_amount == "NaN") {
      curr_amount = 0;
    }

    if (currency1Rate === undefined && currency2Rate == undefined) {
      if (currency1RateValue > currency2RateValue) {
        return parseFloat(parseFloat(curr_amount) * currency2RateValue).toFixed(2);
      } else {
        return parseFloat(parseFloat(curr_amount) * currency2RateValue).toFixed(2);
      }
    } else {
      if (currency1Rate > currency2Rate) {
        return parseFloat(parseFloat(curr_amount) * currency2Rate).toFixed(2);
      } else {
        return parseFloat(parseFloat(curr_amount) * currency2Rate).toFixed(2);
      }
    }
  };

  const filterData = () => {
    let arr = referrStore.slice((activePage - 1) * pageSize, pageSize * activePage)
    setData(arr)
  }

  useEffect(() => {
    filterData()
  }, [pageSize, activePage, referrStore])

  const handlePageChange = (pgNum) => {
    setActivePage(pgNum)
  }

  const manageIcons = () => {

    if (document.querySelector("#referral_company_block")) {

      if (document.querySelector("#referral_company_block").scrollLeft > 5) {
        setArrows((prev) => ({ ...prev, left: true }));
      } else {
        setArrows((prev) => ({ ...prev, left: false }));
      }

      if (
        document.querySelector("#referral_company_block").scrollLeft >= document.querySelector("#referral_company_block").scrollWidth - document.querySelector("#referral_company_block").clientWidth - 7
      ) {
        setArrows((prev) => ({ ...prev, right: false }));
      } else {
        setArrows((prev) => ({ ...prev, right: true }));
      }
    }
  };



  useEffect(() => {

    if (document.querySelector("#referral_company_block")) {
      document.querySelector("#referral_company_block").addEventListener("scroll", manageIcons);
    }
    return () => {
      if (document.querySelector("#referral_company_block")) {
        document.querySelector("#referral_company_block").removeEventListener("scroll", manageIcons);
      }
    };
  }, []);

  const referral_company_block = document.querySelector("#referral_company_block");

  const handleClick = (direction) => {
    referral_company_block.scrollBy({
      left: direction,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <div className="position-relative m-4">
        <div className={`referral_company_block mb-3 `} id="referral_company_block">
          <div className={`direction_left ${!arrows.left ? "d-none" : ""}`}
            style={{
              position: "absolute",
              left: "0%",
              top: "48%",
              transform: "translate(50%,-50%)",
            }}>
            <ChevronLeft onClick={() => handleClick(-150)} />
          </div>
          {!loading && <button className={`referral_company_button all_btn  ${btn_active_status == "all" && "activeClass"}`}
            style={{ maxHeight: '3rem', fontSize: '0.75rem', background: `${btn_active_status == "all" ? "#327ebc" : ""}`, color: `${btn_active_status == "all" ? "white" : ""}` }}
            onClick={() => {
              setBtn_active_status("all");
              setReferralCodeCompany("");
            }}>
            All
          </button>}
          {!loading && groupedData.map((elem, i) => {
            return elem.stores.map((elemstores, ind) => {
              return (
                <button className={`${referralCodeCompany == elemstores?.referralCode && "activeClass"}`}
                  style={{ maxHeight: '3rem', minWidth: `${elemstores?.storeName.length < 12 ? '100px' : ''}`, background: `${referralCodeCompany == elemstores?.referralCode ? "#327ebc" : ""}`, color: `${referralCodeCompany == elemstores?.referralCode ? "white" : ""}` }}
                  onClick={() => {
                    setBtn_active_status(ind);
                    setReferralCodeCompany(elemstores?.referralCode);
                  }}>
                  <span className="" style={{ overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '12px' }}>
                    {elemstores?.storeName}
                  </span>
                  <span>
                    {countries.map((country_elem) => {
                      return (
                        <span className="" style={{ lineHeight: 'normal', fontSize: '12px' }}>
                          {country_elem.country_code === elemstores?.country.code ? "(" + country_elem.country_name + ` (${elemstores?.country.currency_symbol}) )` : null}
                        </span>
                      );
                    })}
                  </span>
                </button>
              );
            });
          })}
          <div
            className={`direction_right ${!arrows.right ? "d-none" : "d-block"}`}
            style={{
              position: "absolute",
              right: "2%",
              top: "48%",
              transform: "translate(50%,-50%)",
            }}>
            {!loading && <ChevronRight onClick={() => handleClick(100)} />}
          </div>
        </div>
      </div>
      {!loading ?
        !showReferStore ?
          <>
            <div
              className=""
              style={{ background: "", padding: '0.5rem' }}>
              <div onClick={() => setShowReferStore(true)} style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ color: '#327ebc', display: 'flex' }}><ChevronLeft /></span>
                <span
                  style={{
                    // alignSelf: "center",
                    fontSize: "16px",
                    color: "#327ebc",
                    // marginLeft: "-6px",
                  }}>
                  Back
                </span>
              </div>
            </div>
            <div className="p-3">
              <h4 className="referral_details_header mb-3" style={{ fontSize: '1rem' }}>{storeName}- Subscription Earning Details{" "}</h4>
            </div>
            <Table stripped className="border">
              <thead className="border">
                <tr>
                  <th className="border">#</th>
                  <th className="border">Invoice No</th>
                  <th className="border">Invoice Date</th>
                  <th className="border">Description</th>
                  <th className="border">Subscription Amount</th>
                  <th className="border">Status</th>
                  <th className="border">Commission you earned</th>
                </tr>
              </thead>
              <tbody>
                {
                  storeInvoices?.length > 0 ?
                  storeInvoices.filter(statusElem=>statusElem.status==1).map((elem, i) => {
                    return (
                      <>
                        
                          <tr>
                            <td className="border">{i + 1}</td>
                            <td className="border">{elem.invoice_number}</td>
                            <td className="border">
                              {moment(elem.payment_date).format(
                                "DD-MM-YYYY/HH:mm"
                              )}
                            </td>
                            <td className="border">{elem.invoice_title}</td>
                            <td className="border" style={{ width: "10rem" }}>
                              <span
                                className=""
                                style={{
                                  // fontSize: "1rem",
                                  fontWeight: 500,
                                }}>
                                {currencyCode1!==undefined?currencyCode1:merchantData?.country?.currency_code}{" "}
                                {parseFloat(elem.total).toFixed(2)}
                              </span>
                            </td>
                            <td className="border" style={{ width: "8rem" }}>
                              {elem.status === 1 ? (
                                <div className="d-flex">
                                  <span
                                    className="text-success"
                                    style={{ fontWeight: 500 }}>
                                   Paid
                                  </span>
                                </div>
                              ) : (
                                <div className="d-flex ">
                                  <span
                                    className="text-danger"
                                    style={{ fontWeight: 500 }}>
                                    Unpaid
                                  </span>
                                </div>
                              )}
                            </td>

                            <td className="border" style={{ width: "10rem" }}>
                              {elem.status === 1 && (
                                <div className="d-flex ">
                                  <span
                                    className="text-success"
                                    style={{ fontWeight: 500 }}>
                                    {merchantData?.country.currency_code}{" "}
                                    {((merchantData?.country
                                      .currency_code !== currencyCode) && currency1RateValue!==undefined)
                                      ? changeCurrency(
                                          parseFloat(
                                            (referralCommissionNewType==1?elem.sub_total:elem.total) * referralCommission
                                          ).toFixed(2),
                                          merchantData?.country
                                            .currency_code,
                                          currencyCode
                                        )
                                      : parseFloat(
                                        (referralCommissionNewType==1?elem.sub_total:elem.total) * referralCommission
                                        ).toFixed(2)}
                                  </span>

                                  {((merchantData?.country
                                      .currency_code !== currencyCode) && currency1RateValue!==undefined) ? (
                                    <>
                                      <span
                                        id={"currencyInfoTooltip"+i+1}
                                        className="ml-2 d-flex">
                                        <Icon
                                          path={mdiInformation}
                                          size={.7}
                                          color={"gray"}
                                        />
                                      </span>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"currencyInfoTooltip"+i+1}>
                                        <div className="p-2 text-left">
                                          {`${currencyCode1} ${
                                             (referralCommissionNewType==1?elem.sub_total:elem.total)
                                          } X ${referralCommission*100}% X ${parseFloat(
                                            currency2RateValue
                                          ).toFixed(2)}= ${
                                            merchantData?.country
                                              .currency_code
                                          }${" "}${
                                            merchantData?.country
                                              .currency_code !==
                                            currencyCode
                                              ? changeCurrency(
                                                  parseFloat(
                                                    (referralCommissionNewType==1?elem.sub_total:elem.total) * referralCommission
                                                  ).toFixed(2),
                                                  merchantData?.country
                                                    .currency_code,
                                                  currencyCode
                                                )
                                              : parseFloat(
                                                (referralCommissionNewType==1?elem.sub_total:elem.total) * referralCommission
                                                ).toFixed(2)
                                          }`}
                                        </div>
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <span
                                        id={"currencyInfoTooltip"+i+1}
                                        className="ml-2 d-flex">
                                        <Icon
                                          path={mdiInformation}
                                          size={.7}
                                          color={"gray"}
                                        />
                                      </span>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"currencyInfoTooltip"+i+1}>
                                        <div className="p-2 text-left">
                                          {" "}
                                          {`${
                                            merchantData?.country
                                              .currency_code
                                          } ${parseFloat(
                                            (referralCommissionNewType==1?elem.sub_total:elem.total)
                                          ).toFixed(2)} X ${referralCommission*100}%`}{" "}
                                        </div>
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                </div>
                              )}
                            </td>
                          </tr>
                        
                      </>
                    );
                  })
                    :
                    (
                      <tr>
                        <td colSpan={7}>
                          <h5 className="my-2 text-center" style={{ fontSize: '1rem' }}>
                            No results found
                          </h5>
                        </td>
                      </tr>
                    )
                }
              </tbody>
            </Table>
          </>
          :
          <div>
            <div className="m-3 p-2">
              <Table className="border">
                <thead className="border">
                  <tr>
                    <th className="border">#</th>
                    <th className="border">Date of joining</th>
                    <th className="border">Store(s)</th>
                    <th className="border" width="165px">Referral cashbacks</th>
                    <th className="border" width="165px">Subscription Earnings</th>
                    <th className="border" width="165px">
                      <div style={{ maxWidth: "150px",display:'flex',alignItems:'center' }}>
                        <span>Total Earnings</span>
                        <span className="ml-1 d-inline-flex" id="earningTitle"><Icon path={mdiInformation} size={0.7} color={"black"} /></span>
                      </div>
                      <UncontrolledTooltip
                        placement="top"
                        target="earningTitle">
                        <div className="p-2 text-left">
                          {" "}Referral Cashbacks + Subscription Earnings{" "}
                        </div>
                      </UncontrolledTooltip>
                    </th>
                    <th className="border">Subscription Plan Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.length > 0 ? data.map((elem, i) => {
                      return (
                        <>
                                  {elem.subscription_fees ? (
                                    <tr>
                                      <td className="border">
                                        {  i +
                                          1}
                                      </td>
                                      <td className="border">
                                        {moment(
                                          elem.subscription_fees.start_date
                                        ).format("DD/MM/YYYY")}
                                      </td>
                                      <td className="border">
                                        <div className="d-flex flex-column">
                                          <strong> {elem.business_name}</strong>
                                          {
                                            elem.name?.includes(' ')?
<span>{elem.name?.slice(0,elem.name?.indexOf(' ')+1)}</span>:
  <span>{elem.name}</span>

                                          }
                                          
                                        
                                          <span className="d-flex align-items-center">
                                        
                                            <span className="">
                                            {elem.country.phone_prefix}{" "}
                                            {elem.mobile.slice(0,(elem.mobile.length - 2)).split('').map(elem=><>*</>)}{elem.mobile.slice((elem.mobile.length - 2))}</span>
                                          </span>
                                          <span>
                                            {
                                              elem.email.indexOf('@')==0 || elem.email.indexOf('@')==1  || elem.email.indexOf('@')==2 ?
                                              elem.email
                                            :<>
                                         {elem.email.slice(0,1)}{elem.email.slice(1,(elem.email.indexOf('@') - 1)).split('').map(elem=><>*</>)}{elem.email.slice((elem.email.indexOf('@') - 1))}
                                         </>

                                            }
                                            </span>
                                            {/* <span>{elem.email}</span> */}
                                        </div>
                                      </td>
                                      <td className="border" style={{ width: "2rem" }}>
                                        <span
                                          className="text-success"
                                          style={{
                                            // fontSize: "1rem",
                                            fontWeight: 500,
                                          }}>
                                          {
                                            merchantData?.country
                                              .currency_symbol
                                          }
                                          {parseFloat(elem.referral_referral_cashback).toFixed(2)}
                                        </span>
                                      </td>
                                      <td className="border" style={{ width: "2rem" }}>
                                        <div className="d-flex flex-column">
                                          <span
                                            className="text-success"
                                            style={{
                                              // fontSize: "1rem",
                                              fontWeight: 500,
                                            }}>
                                            {
                                              merchantData?.country
                                                .currency_symbol
                                            }
                                            {(merchantData?.country
                                              .currency_code !==
                                            elem?.country.currency_code && elem?.referral_exchange_rate!==undefined)
                                              ? changeCurrency(
                                                  elem.invoices_details.length >
                                                    0
                                                    ? (parseFloat(
                                                        elem.invoices_details
                                                          ?.filter(
                                                            (data) =>
                                                              data.status === 1
                                                          )
                                                          ?.map(
                                                            (datum) =>elem?.referree_commission_new_type==1?datum.sub_total:datum.total
                                                          )
                                                          ?.reduce(
                                                            (a, b) => a + b
                                                          ) * Number(elem?.referree_commission!==undefined?elem?.referree_commission:0.4)
                                                      ).toFixed(2))
                                                    : parseFloat(0).toFixed(2),
                                                  elem?.referral_exchange_rate
                                                    ?.referree_currency,
                                                  elem?.referral_exchange_rate
                                                    ?.referror_currency,
                                                  elem?.referral_exchange_rate
                                                    ?.referree_currency_rate,
                                                  elem?.referral_exchange_rate
                                                    ?.referror_currency_rate
                                                )
                                              : elem.invoices_details.length > 0
                                              ? parseFloat(
                                                  elem.invoices_details
                                                    ?.filter(
                                                      (data) =>
                                                        data.status === 1
                                                    )
                                                    ?.map(
                                                      (datum) =>elem?.referree_commission_new_type==1?datum.sub_total:datum.total
                                                    )
                                                    ?.reduce((a, b) => a + b) *
                                                    Number(elem?.referree_commission!==undefined?elem?.referree_commission:0.4)
                                                ).toFixed(2)
                                              : parseFloat(0).toFixed(2)}
                                          </span>
                                          <span
                                            style={{
                                              color: "black",
                                              textDecoration: "underline",
                                              marginTop: ".1rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              showStoreInvoice();
                                              setStoreInvoices(
                                                elem.invoices_details
                                              );
                                              setReferralCommission(
                                                elem?.referree_commission!==undefined?elem?.referree_commission:0.4
                                              );
                                              setReferralCommissionNewType(
                                                elem?.referree_commission_new_type==1?1:0
                                              )
                                              setStoreName(elem.business_name);
                                              setCurrencyCode(
                                                elem.country.currency_code
                                              );
                                              setCurrencyCode1(
                                                elem?.referral_exchange_rate
                                                  ?.referree_currency
                                              );
                                              setCurrencyCode2(
                                                elem?.referral_exchange_rate
                                                  ?.referror_currency
                                              );
                                              setcurrency1Rate(
                                                elem?.referral_exchange_rate
                                                  ?.referree_currency_rate
                                              );
                                              setcurrency2Rate(
                                                elem?.referral_exchange_rate
                                                  ?.referror_currency_rate
                                              );
                                            }}>
                                            View Details
                                          </span>
                                        </div>
                                      </td>
                                      <td className="border" style={{ minWidth: "12rem" }}>
                                        <div className="d-flex flex-column">
                                          <span
                                            className="text-success"
                                            style={{
                                              // fontSize: "1rem",
                                              fontWeight: 500,
                                            }}>
                                            {
                                              merchantData?.country
                                                .currency_symbol
                                            }
                                            {
                                            (merchantData?.country
                                              .currency_code !==
                                            elem?.country.currency_code && elem?.referral_exchange_rate!==undefined)
                                              ? parseFloat(parseFloat(changeCurrency(
                                                  elem.invoices_details.length >
                                                    0
                                                    ? (parseFloat(
                                                        elem.invoices_details
                                                          ?.filter(
                                                            (data) =>
                                                              data.status === 1
                                                          )
                                                          ?.map(
                                                            (datum) =>elem?.referree_commission_new_type==1?datum.sub_total:datum.total
                                                          )
                                                          ?.reduce(
                                                            (a, b) => a + b
                                                          ) * Number(elem?.referree_commission!==undefined?elem?.referree_commission:0.4)
                                                      ).toFixed(2))
                                                    : parseFloat(0).toFixed(2),
                                                  elem?.referral_exchange_rate
                                                    ?.referree_currency,
                                                  elem?.referral_exchange_rate
                                                    ?.referror_currency,
                                                  elem?.referral_exchange_rate
                                                    ?.referree_currency_rate,
                                                  elem?.referral_exchange_rate
                                                    ?.referror_currency_rate
                                                ))+parseFloat(elem.referral_referral_cashback)).toFixed(2)
                                              : elem.invoices_details.length > 0
                                              ? parseFloat(
                                                elem.referral_referral_cashback+elem.invoices_details
                                                    ?.filter(
                                                      (data) =>
                                                        data.status === 1
                                                    )
                                                    ?.map(
                                                      (datum) =>elem?.referree_commission_new_type==1?datum.sub_total:datum.total
                                                    )
                                                    ?.reduce((a, b) => a + b) *
                                                    Number(elem?.referree_commission!==undefined?elem?.referree_commission:0.4)
                                                ).toFixed(2)
                                              : parseFloat(0+elem.referral_referral_cashback).toFixed(2)}
                                          </span>
                                          
                                        </div>
                                      </td>
                                      <td className="border">
                                        {elem.subscription_fees.status === 1 ? (
                                          <div className="d-flex flex-column ">
                                            <span
                                              className="text-success"
                                              style={{ fontWeight: 500 }}>
                                              Active
                                            </span>
                                            {elem.invoices_details.length >
                                            0 ? (
                                              <span>
                                                Expires on-{" "}
                                                {moment(
                                                  elem.subscription_fees
                                                    .end_date
                                                ).format("DD/MM/YYYY")}
                                                )
                                              </span>
                                            ) : (
                                              <span>Forever Free Plan</span>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="d-flex ">
                                            <span
                                              className="text-danger"
                                              style={{ fontWeight: 500 }}>
                                           Inactive
                                            </span>
                                            <span
                                              id="titalIncomeInfoTooltip"
                                              className="ml-2 d-flex">
                                              <Icon
                                                path={mdiInformation}
                                                size={0.7}
                                                color={"red"}
                                              />
                                            </span>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target="titalIncomeInfoTooltip">
                                              <div className="p-2 text-left">
                                                {" "}
                                                Call merchant to renew plan so that you can earn subscription commission{" "}
                                              </div>
                                            </UncontrolledTooltip>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  ) : null}
                                </>
                      )
                    }) : (
                      <tr>
                        <td colSpan={7}>
                          <h5 className="my-2 text-center" style={{ fontSize: '14px' }}>
                            No results found
                          </h5>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </div>
            {referrStore.length > 0 && <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px", margin: "1rem 0rem" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <select className="custom-select" style={{ width: "32%", marginRight: "7px" }} onChange={(e) => { setPageSize(e.target.value); filterData(); }} value={pageSize}>
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                </select>
                <span>Records per Page</span>
              </div>
              <PaginationComponent
                totalItems={referrStore.length}
                pageSize={pageSize}
                onSelect={handlePageChange}
                defaultActivePage={activePage}
              />
            </div>}
          </div>
        :
        <div className="loader-box d-flex align-item-center justify-content-center" style={{ height: "50vh", width: "100wh" }}>
          <DisappearedLoading size="medium" color="#514F4E" style={{ position: "unset" }} />
        </div>
      }
    </Fragment>
  )
}

export default AffiliateStores;