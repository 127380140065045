import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { Row, Col, Popover, PopoverBody, Button, Modal, ModalBody } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import BeatLoader from "react-spinners/BeatLoader";


const Subscription = ({ staticFeatures }) => {

    let [loading, setloading] = useState(true);
    let [loadingItem, setloadingItem] = useState(null);
    let [loadingpopular, setloadingpopular] = useState(null);
    let [loadingItemp, setloadingItemp] = useState(null);
    let [features, setfeatures] = useState([]);
    let [result, setResult] = useState([]);
    let [forceReload, setForceReload] = useState(1);
    let [periods, setperiods] = useState([]);
    const [showActive, setShowActive] = useState(true)
    const history = useHistory();
    const [activePlans, setActivePlans] = useState(false)
    const [mergeModal, setmergeModal] = useState(false)
    const [loadingupdate, setloadingupdate] = useState(false)

    const togglemergeModal = () => {
        setmergeModal(!mergeModal)
    }
    const [featureDescArr, setFeatureDescArr] = useState([
        {
            cloud_based_desc: "",
            loyalty_card_desc: "",
            offers_vouchers_desc: "",
            online_order_desc: "",
            payment_link_desc: "",
            register_desc: "",
            table_reservation_desc: "",
            users_roles_desc: "",
            visitor_registry_desc: "",
            linky_scan_desc: "",
            price_list_desc: "",
        }
    ])
    // console.log(result, "result")
    const [popoverState, setPopoverState] = useState({
        // cloud_based: false,
        // loyalty_card: false,
        // offers_vouchers: false,
        // online_order: false,
        // payment_link: false,
        // register: false,
        // table_reservation: false,
        // users_roles: false,
        // visitor_registry: false,
        // linky_scan: false,
        // price_list: false,

        sales_per_day: false,
        number_of_registers: false,
        number_of_users: false,
        guest_visitor_registry: false,
        linky_scan_id: false,
        ecommerce_website: false,
        bookings_reservations: false,
        offers_vouchers: false,
        loyalty_cards: false,
        payment_links: false,
        price_list: false,
        remove_logo: false
    })

    useEffect(() => {

        fetch_sub()
        fetchperiod();
        fetchfeatures();

    }, [forceReload])

    const fetch_sub = () => {
        client.get(api.fetch_subscription, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setResult(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }
            setloadingpopular(null)

            setloading(false);
        });
    }

    const fetchperiod = () => {

        client.get(api.fetchperiod, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setperiods(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }

            // setloading(false);
        });

    }

    const toggleStatusPeriod = (item, index) => {

        setloadingItemp(item._id);

        client.post(api.toggle_status_period, { period_id: item._id }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let s = periods;
                            s[index].status = s[index].status == 1 ? 2 : 1;
                            // console.log(s)
                            setperiods(s)
                            // alert("here")
                        } else {
                        }
                    } catch (e) {
                        // alert(e)
                    }
                } else {
                }
            } else {
            }
            setloadingItemp(null)
        });
    }

    const saveFeaturesDesc = (obj, desc) => {

        let postData = { ...featureDescArr[0] }
        client.post(api.save_featureDesc, postData, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    // console.log(response, "Res")
                    toast.success(response.message)
                    setPopoverState({
                        // cloud_based: false,
                        // loyalty_card: false,
                        // offers_vouchers: false,
                        // online_order: false,
                        // payment_link: false,
                        // register: false,
                        // table_reservation: false,
                        // users_roles: false,
                        // visitor_registry: false,
                        // linky_scan: false,
                        // price_list: false,

                    })
                    togglePopOver();
                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Something went wrong")
            }
        })
        let desc_data = {
            feature_id: obj?._id,
            title: obj?.title,
            description: desc,
            status: obj?.status
        }
        // console.log(desc_data)
        // updating to features_descriptions
        client.post(api.edit_features, desc_data, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // toast.success("Updated Successfully");
                            // setcashbackdata(response.result.data);
                            fetchfeatures()
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }

    const fetchfeatures = () => {

        client.get(api.fetch_features, { showall: true }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setfeatures(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }

            // setloading(false);
        });

    }

    const mergeSubscription = () => {
        setloadingupdate(true)

        client.post(api.update_merchants_subscription, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("updated Successfully");
                           
                        } else {
                        toast.error("Internal error occured. Please contact support 1");
                            
                        }
                    } catch (e) {
                        // alert(e)
                        toast.error("Internal error occured. Please contact support 1");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 1");
                }
            } else {
                toast.error("Internal error occured. Please contact support 1");
            }
            togglemergeModal()
            setloadingupdate(false)
        })

    }

    const toggleStatus = (item, index) => {

        setloadingItem(item._id);

        client.post(api.toggle_status, { subscription_id: item._id }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let s = result;
                            s[index].status = s[index].status == 1 ? 2 : 1;
                            // console.log(s)
                            setResult(s)
                            // alert("here")
                        } else {
                        }
                    } catch (e) {
                        // alert(e)
                    }
                } else {
                }
            } else {
            }
            setloadingItem(null)
        });
    }



    const updateSortBy = async (item, sortNo) => {
        const sort_id = item._id
        let data = { sort_id: sort_id, sort_number: sortNo }
        // console.log(data, 'post Data');
        await client.post(api.sort_update, data, function (error, response) {
            // console.log(error, response, "er res")
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("Updated Successfully");
                            // setcashbackdata(response.result.data);
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support 1");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 2");
                }
            } else {
                toast.error("Internal error occured. Please contact support 3");
            }


        });
    }

    const updatePopular = async (item, e) => {
        const popular_id = item._id
        let data = { popular_id: popular_id, state: e.checked }
        // console.log(data, "Data")
        setloadingpopular(item._id)
        await client.post(api.popular_update, data, function (error, response) {
            // console.log(error, response, "er res")
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            fetch_sub()
                            toast.success("updated Successfully");
                            // setcashbackdata(response.result.data);
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support 1");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 2");
                }
            } else {
                toast.error("Internal error occured. Please contact support 3");
            }


        });

    }

    const [enablePopOver, setEnablePopOver] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const togglePopOver = (data) => {
        if (data) {
            setSelectedFeature(data);
        } else {
            setSelectedFeature(null);
        }
        setEnablePopOver(!enablePopOver)
    };

    return (
        <>
            <div style={{ display: 'flex', margin: '0 10px 10px 0px' }}>
                <div style={{display: 'flex', flex :1}}>
                <p style={{ margin: '3px', padding: '2px' }}>Show :</p>
                <div
                    style={{
                        display: 'flex',
                        width: '160px',
                        justifyContent: 'space-between',
                        border: '2px solid #327ebc',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <p style={{
                        background: `${showActive ? '#327ebc' : 'white'}`,
                        color: `${showActive ? 'white' : 'black'}`,
                        margin: '0px',
                        padding: '5px'
                    }}
                        onClick={() => { setShowActive(!showActive) }}
                    >Active Plans </p>
                    <p style={{
                        background: `${!showActive ? '#327ebc' : 'white'}`,
                        color: `${!showActive ? 'white' : 'black'}`,
                        margin: '0px',
                        padding: '5px',
                    }}
                        onClick={() => { setShowActive(!showActive) }}
                    >Show All</p>
                </div>
                </div>

                <Button
                    className="pull-right"
                    color="primary"
                    onClick={(e) => togglemergeModal()}
                >Update Subscription Plans</Button>
                
            </div>
            
            <Row
                className="border mx-2 mainCont"
                style={{ flexWrap: 'nowrap' }}
            >

                <Col className="topCol" style={{ padding: "0px", minWidth: "190px", maxWidth: "190px" }}>
                    <Col className="headerCell" style={{ height: "94px" }} >Title of subscription</Col>
                    <Col className="headerCell" style={{ height: "80px", overflow: "auto" }} >Sub Title</Col>
                    <Col className="headerCell" style={{ height: "50px" }}>Subscription Fee</Col>
                    <Col className="headerCell" style={{ height: "50px" }}>Promotional Details</Col>
                    {
                        staticFeatures.map((item, itemIndx) => {
                            let height;
                            switch (item.feature_id) {
                                case 'guest_visitor_registry':
                                    height = 60
                                    break;
                                case 'ecommerce_website':
                                    height = 70
                                    break;
                                case 'bookings_reservations':
                                    height = 60
                                    break;
                                default:
                                    height = 50;

                            }
                            return (
                                <Col className="headerCell" style={{ height: height, padding: "0px" }}>
                                    <div className="featDiv">
                                        <h5 className="featureName">
                                            {item.title}
                                            <i
                                                class="fa fa-info-circle ml-1"
                                                id={`features-${item._id}`}
                                                onClick={() => {
                                                    togglePopOver(item);
                                                    // setPopoverState(prev => {
                                                    //     return { [item.feature_id]: !prev[item.feature_id] }
                                                    // })
                                                }
                                                }
                                                aria-hidden="true"
                                            ></i>
                                        </h5>
                                        {<Popover
                                            placement="right"
                                            // isOpen={popoverState[item.feature_id]}
                                            isOpen={enablePopOver && selectedFeature && selectedFeature._id == item._id}
                                            target={`features-${item._id}`}
                                            // toggle={() => setPopoverState(prev => {
                                            //     return { [item.feature_id]: !prev[item.feature_id] }
                                            // })}
                                            toggle={() => { togglePopOver() }}
                                        >
                                            <PopoverBody className="py-3">
                                                <textarea
                                                    className="form-control textAreaa"
                                                    defaultValue={item?.description}
                                                    name="cloud_based_desc"
                                                    placeholder="description"
                                                    rows={4}
                                                    cols={60}
                                                    onChange={(e) => {
                                                        e.preventDefault()
                                                        let temp = { ...featureDescArr[0] }
                                                        temp.cloud_based_desc = e.target.value
                                                        setFeatureDescArr([{ ...temp }])
                                                    }}
                                                />
                                                <div className="d-flex justify-content-between mt-3">
                                                    <Button
                                                        style={{ width: '45%' }}
                                                        color="grey"
                                                        onClick={() => {
                                                            togglePopOver();
                                                            setPopoverState(prev => {
                                                                return { [item.feature_id]: !prev[item.feature_id] }
                                                            })
                                                        }}>Cancel
                                                    </Button>
                                                    <Button
                                                        style={{ width: '45%' }}
                                                        onClick={(e) => saveFeaturesDesc(item, featureDescArr[0].cloud_based_desc)}
                                                    >Save</Button>
                                                </div>
                                            </PopoverBody>
                                        </Popover>}
                                    </div>
                                </Col>
                            )
                        })}
                    {/* <Col  >Modifiers</Col> */}
                    {/* <Col className="headerCell" style={{ height: "50px" }}>Action</Col>
                    <Col className="headerCell" style={{ height: "50px" }}>Popular</Col> */}
                    <Col className="headerCell" style={{ height: "50px" }}>Sort By</Col>
                    {/* <Col className="headerCell" style={{ height: "50px" }}></Col> */}
                </Col>


                <div style={{ width: '90%', overflowX: "auto", display: "flex" }}>
                    {result && result.length > 0 && result.map((item, index) => {
                        // console.log(item, "item")
                        return (
                            <div>
                                {(showActive ? item.status === 1 : true) &&
                                    <Col key={item._id} className="topCol" style={{ padding: "0px", minWidth: "210px", maxWidth: "210px" }}>
                                        {
                                            <Col className="dataCell" style={{ display : 'block', padding : 10, height : "94px" }}>
                                                <div>
                                                    <span>
                                                        {item.title}
                                                        {item.is_trial &&
                                                            <span style={{ marginLeft: 10, fontSize: 8, backgroundColor: "orange", padding: "1px 4px", border: "1px solid orange", borderRadius: 8, color: "#fff" }}>Trial</span>
                                                        }
                                                    </span>

                                                    <span style={{ marginRight: "10px", position: 'absolute', right: 0, fontSize: "0.75rem", color: "black" }} onClick={() => {
                                                        history.push(`${process.env.PUBLIC_URL}/subscriptions/edit/${item._id}`);
                                                    }} >
                                                    
                                                        <i className="fa fa-edit"></i>
                                                    </span>
                                                </div>
                                                {
                                                    <div style={{ marginTop: 6, display: 'flex', justifyContent: "space-between" }}>
                                                        <span>Activate / Deactivate</span>
                                                        {/* <Col className="dataCell" style={{ height: "50px" }}> */}
                                                            {loadingItem && loadingItem == item._id ?
                                                                <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                                :
                                                                <Switch
                                                                    onChange={() => toggleStatus(item, index)}
                                                                    checked={item.status == 2 ? false : true}
                                                                    className="react-switch"
                                                                    disabled={item?.is_free === true}
                                                                    height={20}
                                                                    width={40}
                                                                />

                                                            }
                                                        {/* </Col> */}
                                                    </div>
                                                }
                                                <div style={{marginTop : 6, display : 'flex'}}>
                                                    {loadingpopular && loadingpopular == item._id ?
                                                        <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                        :
                                                        <>
                                                            {/* <Switch
                                                                onChange={() => {
                                                                    updatePopular(item, { checked: !item.popular })
                                                                }
                                                                }
                                                                checked={item.popular}
                                                                className="react-switch"
                                                                // disabled={item?.is_free === true}
                                                                height={20}
                                                                width={40}
                                                            /> */}
                                                            {true &&
                                                                <div
                                                                    
                                                                    onClick={() => {
                                                                        updatePopular(item, { checked: !item.popular })
                                                                    }
                                                                    }
                                                                
                                                                    style={{
                                                                    // marginLeft: "10px",
                                                                    background: item.popular ? "#FED3A1" : 'gray',
                                                                    color: item.popular ? "#313131" : '#fff',
                                                                    padding: "2px 12px",
                                                                    borderRadius: "10px",
                                                                    fontSize: "9px",
                                                                    textTransform: "uppercase",
                                                                    fontWeight: "bold",
                                                                    display: "flex",
                                                                    alignSelf: "center",
                                                                    // marginLeft: 10,
                                                                }}>Most Popular</div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </Col>
                                        }
                                        <Col className="dataCell" style={{ height: "80px", overflow: "auto" }} >{item.description}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.fees}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>
                                            {item.promo_rate > 0 ?
                                                <span>Fees : {item.promo_rate} {item.promo_rate_type==2 ? '%' : ''}<br />
                                                    End Date : {moment(item.promo_end_date).format("YYYY-MM-DD")}</span>
                                                :
                                                <span>-</span>

                                            }
                                        </Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.unlimited_sales ? `Unlimited` : item.sales_per_day}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.unlimited_registration ? `Unlimited` : item.number_of_registers}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.unlimited_users ? `Unlimited` : item.number_of_users}</Col>
                                        <Col className="dataCell" style={{ height: "60px" }}>{item.unlimited_vregistries ? `Unlimited` : item.number_of_vregistries}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.unlimited_linkyScan ? `Unlimited` : item.number_of_linkyScan}</Col>
                                        <Col className="dataCell" style={{ height: "70px" }}>{item.online_orders ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "60px" }}>{item.table_reservation ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.function_booking ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.offers_vouchers ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.loyalty_cards ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.payment_links ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.price_list ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.remove_logo ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.tips_reviews ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.call_waiter ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.clean_table ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.customize_themes_fonts ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.review_booster ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.linky_meet ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.sell_ticket ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.xero_integration ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.invoice_quote ? `Yes` : "No"}</Col>
                                        <Col className="dataCell" style={{ height: "50px" }}>{item.quick_sms ? `Yes` : "No"}</Col>
                                        {/* <Col className="dataCell" style={{ height: "50px" }}>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={() => {
                                                    history.push(`${process.env.PUBLIC_URL}/subscriptions/edit/${item._id}`);
                                                }} >
                                                    <i className="fa fa-edit"></i>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="dataCell" style={{ height: "50px", display: "flex", alignItems: "center" }}>
                                            <input style={{ width: "30px", height: "15px", marginLeft: "3px" }} className="form-check-input" defaultChecked={item.popular} name="popular" id={`popular_${index}`} type="checkbox" value={item._id} onChange={(e) => {

                                                updatePopular(item, e.target)
                                            }} />


                                        </Col> */}
                                        <Col className="dataCell" style={{ height: "50px", display: "flex", alignItems: "center" }} >
                                            <input style={{ width: "40px", height: "30px", marginLeft: "3px" }} defaultValue={item.sort_by} className="form-check-input" name="sort_by" id={`sort_${index}`} type="number"
                                                onChange={(e) => {
                                                    let sort_no = e.target.value;
                                                    if (e.target.value) {
                                                        updateSortBy(item, sort_no);
                                                    }
                                                }} />
                                        </Col>
                                        

                                    </Col>
                                }
                            </div>

                        )
                    })}
                </div>

            </Row >

            <Modal isOpen={mergeModal} toggle={togglemergeModal} className="modal-body" centered={true}>
                <ModalBody>
                    {!loadingupdate ?
                        <>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <img style={{ width: 82 }} src="https://dl.dropboxusercontent.com/scl/fi/l3lio2vr9ryq76kgh8ngc/warning-icon-yellow.png?rlkey=kypnauptl3uw58tr28ossojl2&dl=0" alt="" />
                                <span style={{
                                    fontSize: "24px",
                                    fontWeight: 500
                                }}>Attention!</span>
                            </div>
                            <hr />
                            <h5 style={{
                                textAlign: "center",
                                fontWeight: "unset"
                            }}>Are you sure you want to update the merchant's subscription plan with the current subscription details? < br />The changes can't be reverted.</h5>
                            {/* <div>The changes can't be reverted.</div> */}
                            <Button
                                disabled={loadingupdate}
                                color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={togglemergeModal}>
                                Not Sure
                            </Button>
                            <Button
                                color="danger"
                                disabled={loadingupdate}
                                style={{ marginTop: "20px", marginRight: "20px", float: "right" }}
                                name="unflag"
                                onClick={(event) => mergeSubscription()}
                            >
                                Yes, please
                            </Button>
                        </>
                        :
                        <div className="w-100 h-100 text-center">
                                <BeatLoader color="#45C7A2" loading={true} size={15} />
                            <div style={{ textAlign: 'center'}}>Updating subscription, please wait.</div>
                            </div>
                    }
					
				</ModalBody>
			</Modal>
        </>
    )
}

export default Subscription