import React from 'react';
// import { VscFile } from "react-icons/vsc";

const CustomDropDown = (props) => {


    let { dropdown, toggleDropdown, screenSize, item, index } = props;
    let option = [{ title: "PNG", _id: 1 }, { title: "PDF", _id: 2 }];

    const selectOption = (data) => {
        console.log(data, "selected data")
        // if (data._id == 1) {
        //     downloadQrPNG();
        // } else if (data._id == 2) {
        //     downloadQrPDF()
        // }
    }


    return (
        <div
            key={index}
            className="dropdown_menu_option m-0"
            style={{
                padding: screenSize <= 576 ? "1rem" : "0.4rem 0.5rem",
                cursor: "pointer",
                color: "black",
                textAlign: "start",
                fontSize: screenSize <= 576 ? "18px" : "14px"
            }}
            onClick={(e) => { selectOption(item); }}>
            {/* <VscFile color={'#ccc'} size={17} className="mr-2" /> */}
            {item.title}
        </div>
    )
}

export default CustomDropDown