import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
    Button,
    ButtonToolbar,
    Card,
    CardBody,
    Col,
    Input,
    Container,
    Row,
    Progress,
    Modal,
    Table,
    FormGroup,
    Alert,
} from 'reactstrap';
import moment from 'moment';
import { useHistory, useParams, withRouter } from "react-router";
import { toast, ToastContainer } from 'react-toastify';
import Icon from '@mdi/react'
import { mdiAlertCircle, mdiDownload } from '@mdi/js';
import "./merchants/MerchantInvoiceDetailsCss.scss"
import HTTPClient from ".././Api/HTTPClient"
import api from ".././constant/apilist"
import BeatLoader from "react-spinners/BeatLoader";
import GenerateInvoiceModal from '../components/ui-elements/CustomModal/GenerateInvoiceModal';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const EnquiryInvoice = (props) => {
    const { setInvoiceDetails,invoiceFrom, merchantInfo } = props
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);
    const [whiteLabelInvoice, setWhiteLabelInvoice] = useState(false);
  const history= useHistory()

  const [isInvoiceModalOpen,setInvoiceModalOpen]=useState(false)

	const toggleInvoiceModal=()=>{
		setInvoiceModalOpen(!isInvoiceModalOpen)

	}

    console.log(invoiceData, 'inVDATA')

    const {invoice_id}=useParams()

    useEffect(() => {
        getEnquiry()

    }, [invoice_id])

    const getEnquiry =()=>{
        setLoading(true)
        if(invoice_id)
        {

        HTTPClient.post(api.getEnquiryById, { invoice_id }, async function (error, response) {
            // console.log(response, "fetch_subscription_invoice", error)
            if (!error) {
                // console.log(response, "fetch_subscription_invoice")
                if (!response.error) {
                    console.log(response)
                    if(response.data?.length>0)
                    {
                        setInvoiceData(response.data[0])

                    }
                    // setPaymentLog(response.result.payment_log)
                    setLoading(false)
                } else {
                    toast.error("Internal error occured.Please contact support");
                    setLoading(false)

                }
            }
            else {
                toast.error("Internal error occured.Please contact support");
                setLoading(false)

            }
        })
    }
    }


    const generatePDF = async () => {
        setBtnLoading(true)
        const edit_invoice = document.getElementById('edit_invoice'); // The HTML element you want to convert to PDF
        const approve_btn = document.getElementById('approve_btn'); // The HTML element you want to convert to PDF
        edit_invoice.style.display='none'
        approve_btn.style.display='none'
        const element = document.getElementById('invoice-page'); // The HTML element you want to convert to PDF
        setTimeout(()=>{
            edit_invoice.style.display='block'
        approve_btn.style.display='block'
        },500)
        html2canvas(element, {
            scale: 2,  // Increase quality
            useCORS: true,  // Handle CORS for external images
          })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF("p", "mm", "a4");
          const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
          pdf.addImage(imgData, 'JPEG', 0, 0,pdfWidth,pdfHeight);
          // pdf.output('dataurlnewwindow');
          approveInvoice(pdf);
        })
          

              
          };

       
    
      

    // console.log(invoiceData)

    const approveInvoice=async(pdf)=>{
        
        let postData={
            _id:invoiceData?.invoice_details[0]?._id,
            merchant_id:invoiceData?.invoice_details[0]?.merchant_id,
            status:2,
            enquiry_id:invoice_id
            
        }
        

        const moneyPostData ={
            merchant_id: invoiceData?.invoice_details[0]?.merchant_id,
            enquiry_id:invoice_id,
            request_type: 1,
            amount: Number((invoiceData?.invoice_details[0]?.invoice_total)),
            charges_payer: 1,
            request_from: 13
          }

         
        //   console.log(pdf.save())

        HTTPClient.post(api.moneyrequest, moneyPostData, async function (error, response) {
            console.log(response,error)
        
            if (!error) {
        
                if (!response.error) {

                    const pdfBase64 = pdf.output('datauristring').split(',')[1];

                    
                    HTTPClient.post(api.enquiry_invoice,{token_id:response?.token_id,pdfBase64:pdfBase64,...postData} , async function (error, response) {
                        console.log(response,error)
                    
                        if (!error) {
                    
                            if (!response.error) {
                                
                                history.push('/function-bookings')
                            } 
                            else {
                                toast.error("Internal error occured. Please contact support");
                            }
                    
                        } else {
                            toast.error("Internal error occured. Please contact support");
                        }
                    
                    });
                } 
                else {
                    toast.error("Internal error occured. Please contact support");
                }
        
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        
        });

      
        
       
            }

    

    const ShowCurrencyNum = (amount, showSymbol) => {
        // const merchantData = JSON.parse(localStorage.getItem("merchant"));
        const merchantData = invoiceData?.merch_info;
        const currency_symbol = merchantData.country.currency_symbol;
        // const currency_symbol = countryData.currency_symbol;

        let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

        if (merchantData && merchantData.country) {
            // if (countryData) {
            if (showSymbol && currency_symbol) {
                getCurrency = `${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            }
            else {
                getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            }
        }

        return getCurrency;
    }


    return (
        <Card className='merchantInvoice'>
            {!loading ? <CardBody>
                {invoiceData && <Container style={{ marginTop: "20px" }}>
                    <Row>
                        <Col md={12}>
                            <div className='text-right'>

                                {/* <span className="mr-4 font-weight-bold" style={{ cursor: 'pointer', color: 'gray' }}>
                                    <Icon path={mdiDownload} size={1.2} /> PDF</span> */}
                                <Button
                                    className='my-0'
                                    type='button'
                                    size='sm'
                                    color='primary'
                                    outline

                                    onClick={() => {
                                        history.push('/function-bookings')
                                    }
                                    }
                                > Go Back </Button>

                            </div>
                            <Row className='pt-4 pb-3' id='invoice-page'>
                                <Col md={12}>
                                    <div className='border rounded p-4'>
                                        <Row>
                                            <Col md={6} className='d-flex align-items-center justify-content-start'>
                                                <img
                                                    style={{width:'100px'}}
                                                    src='https://dmapi.ipaypro.co//images/logo-business-type/97e1d5af-7a88-421f-a7cc-3c7f6cf90829_1720159310703.png'
                                                />
                                            </Col>
                                            <Col md={6} className='d-flex align-items-center justify-content-end'>
                                                <div>
                                                    <p className='invoice__grand-total' > Invoice #{invoiceData?.invoice_details[0]?.invoice_id} </p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='py-3 border-top'>
                                            <Col md={8} className='text-left'>
                                                <div>
                                                    <p className='my-0'> Linky </p>
                                                    <p className='my-0'>
                                                        {/* {invoiceFrom.address.split(',').map((line, i) => (
                                                            <span key={i}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        ))
                                                        } */}
                                                       234, Hoddle St. Abbotsford VIC 3067, Australia
                                                    </p>
                                                    
                                                        <p dir="ltr" className='my-0'> ABN - 89604800419 </p>
                                                    
                                                   
                                                        <a href={'https://linky.biz/'} target='_blank'> {'https://linky.biz/'} </a>
                                                    
                                                    {/* <a href='https://www.store.com' target='_blank'> www.store.com </a> */}
                                                </div>
                                            </Col>
                                            <Col md={4} className='text-right'>
                                                <div>
                                                    <p className="invoice__date my-0"> Date/Time : {moment(invoiceData?.invoice_details[0]?.invoice_upload_date).tz(invoiceData?.merch_info?.timeZone).format('DD-MM-YYYY/hh:mm A')} </p>
                                                    <p className='my-0' style={{ textTransform: "capitalize" }}> {invoiceData?.merch_info?.business_name} </p>
                                                    {/* <p className='my-0'> {invoiceData?.fk_merchant_id?.address} </p>
                                                     */}
                                                    <p className='my-0'>
                                                        {/* {invoiceData?.fk_merchant_id?.address.split(',').map((line, i) => (
                                                            <span key={i}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        ))
                                                        } */}
                                                        {invoiceData?.merch_info?.address}
                                                    </p>
                                                    {/* <p className='my-0'> Australia </p> */}
                                                    {invoiceData?.fk_merchant_id?.country?.code == "AU" && invoiceData?.fk_merchant_id?.company_id?.abn && invoiceData?.fk_merchant_id?.company_id?.abn != "" &&
                                                        <p dir="ltr" className='my-0'> ABN - {invoiceData?.fk_merchant_id?.company_id?.abn} </p>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='py-3'>
                                            <Col md={12}>
                                                <Table className="table--bordered" responsive striped>
                                                    <thead>
                                                        <tr>
                                                            <th> # </th>
                                                            <th> Plan </th>
                                                            { <th> Quantity </th>}
                                                            {<th> Unit Cost </th>}
                                                            <th> Total </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            <tr>
                                                                <td>1</td>
                                                                <td>{`#${invoiceData?.unique_id} - ${invoiceData?.eventType}`}</td>
                                                               <td>1</td>
                                                                {/* {whiteLabelInvoice ? null : <td>${invoiceData.sub_total.toFixed(2)}</td>} */}
                                                                {!invoiceData ? null :
                                                                    <td>{ShowCurrencyNum(invoiceData?.invoice_details[0]?.final_spend_amount?.toFixed(2), true)}</td>}
                                                                {/* <td>${invoiceData.sub_total.toFixed(2)}</td> */}
                                                                <td>{ShowCurrencyNum(invoiceData?.invoice_details[0]?.final_spend_amount?.toFixed(2), true)}</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12} className='text-right'>
                                                <div className="invoice__total">
                                                    {/* <p> Sub - total amount: ${(invoiceData.sub_total).toFixed(2)} </p> */}
                                                    <p className='mb-0 mt-3'> Subtotal: {ShowCurrencyNum((invoiceData?.invoice_details[0]?.final_spend_amount)?.toFixed(2), true)} </p>
                                                    <p className='mb-0'> Linky Commission: {ShowCurrencyNum((invoiceData?.invoice_details[0]?.linky_commission)?.toFixed(2), true)} </p>
                                                    
                                         
                                                    {/* <p> {item.title}: ${(item.amount).toFixed(2)} </p> */}
                                                    {/* <p className="invoice__grand-total"> Grand Total: ${(invoiceData.total).toFixed(2)} </p> */}
                                                    <h6 className=" my-3"> Total Due: {ShowCurrencyNum((invoiceData?.invoice_details[0]?.invoice_total)?.toFixed(2), true)} </h6>
                                                    {
                                                        invoiceData?.invoice_details[0]?.status==1 &&
                                                    
                                                    <ButtonToolbar className="invoice__toolbar justify-content-center">
                                                        <Button color="primary" disabled={btnLoading?true:false} className='generate_invoice' outline onClick={(e)=>{generatePDF()
                                                            e.target.blur()
                                                        }} id={'approve_btn'}>
                                                            {
                                                                btnLoading?<BeatLoader
                                                                color={"#327ebc"}
                                                                loading={true}
                                                                size="5"
                                                            />:'Approve'
                                                            }
                                                             </Button>
                                                        <Button outline className='ml-2 generate_invoice' disabled={btnLoading?true:false} onClick={(e)=>{toggleInvoiceModal()
                                                            e.target.blur()
                                                        }} id={'edit_invoice'}> Edit Invoice </Button>
                                                    </ButtonToolbar>
}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <GenerateInvoiceModal isInvoiceModalOpen={isInvoiceModalOpen} toggleInvoiceModal={toggleInvoiceModal} invoiceDetails={invoiceData} getEnquiry={getEnquiry}/>
                </Container>}

            </CardBody> :
                <div className='loaderDiv'>
                    <BeatLoader
                        color={"#fff"}
                        loading={true}
                        size="7"
                    />
                </div>
            }
        </Card>
    )
}

export default withRouter(EnquiryInvoice);
