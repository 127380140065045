import React, { Fragment, useEffect, useState } from 'react'
import client from "../../Api/HTTPClient";
import "./css/profile.css"
import api from "../../constant/apilist";
import axios from "axios";
import no_image from "../../assets/images/No-Image.png";
import { ToastContainer, toast } from "react-toastify";
import { Nav, NavItem, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from "reactstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment-timezone";
import EyeIcon from "mdi-react/EyeIcon";
import EyeOffIcon from "mdi-react/EyeOffIcon";
import useForm from "react-hook-form";
import MerchantUserPasswords from "./ManageUserPassCom/merchantUserPasswords";
import MerchantUserTransactionPin from "./ManageUserPassCom/merchantUserTransactionPin";
import MerchantUserPins from "./ManageUserPassCom/mangeMerchantUserPins";
import RenderDropZoneField from "./UserProfile";
import Compressor from 'compressorjs';

export const MerchantUserProfile = (props) => {


    const { user_details, merchant_id, merchant_info } = props;
    const [temp_password_reason, settemp_password_reason] = useState("");
    const [password, setpassword] = useState("");
    const [generatePasswordModal, setgeneratePasswordModal] = useState(false);
    const [textCopy, settextCopy] = useState(false);
    const [comments, setcomments] = useState([]);
    const [timeStamp, setTimeStamp] = useState(0);
    const [formData, setFormData] = useState({
        fname: user_details?.FirstName || user_details?.name.split(" ")[0],
        lname: user_details?.LastName || user_details?.name.split(" ")[1],
        email: user_details?.email || ""
    })
    useEffect(() => {
        if (user_details) {
            setFormData({
                fname: user_details?.FirstName || user_details?.name.split(" ")[0],
                lname: user_details?.LastName || user_details?.name.split(" ")[1],
                email: user_details?.email || ""
            })
        }
    }, [user_details])

    useEffect(() => {
        setTimeStamp(Date.now());
    }, []);

    const uploadProfile = (data, { file }) => {
        new Compressor(data, {
            quality: 0.7,
            maxWidth: 1920,
            maxHeight: 1920,
            convertSize: 500000,
            success(result) {

                let formData = new FormData();    //formdata object

                formData.append('user_id', user_details._id);
                formData.append('file', result);

                const config = {
                    headers: {
                        "x-access-token": "ipay-access-token",
                        "authorization": localStorage.getItem("token"),
                    }
                }

                axios.post(`${client.url(api.updateUserProfile)}`, formData, config)
                    .then(response => {
                        if (!response.data.error) {
                            toast.success("Updated.");
                            window.location.reload();
                        } else {
                            toast.error("Internal error occured.Please contact support");
                        }
                    })
                    .catch(error => {
                        toast.error(error);
                    });
            },
            error(err) {
                console.log(err.message);
            },
        });
    }

    const replaceDropboxDlURl = (dropboxUrl) => {
        let withTimestamp = dropboxUrl?.replace('?', `?${timeStamp}&`)
        let withReplaceDropboxURl = withTimestamp?.replace('dropbox.com', 'dl.dropboxusercontent.com')
        return withReplaceDropboxURl;
    }

    // const image_url = merchant_info?.profile_image ? merchant_info.profile_image?.includes("dropbox") ? merchant_info.profile_image : client.url(`images/user-profile/${merchant_info.profile_image}`) : "";
    const image_url = user_details?.profile_image ? user_details.profile_image?.includes("dropbox") ? replaceDropboxDlURl(user_details.profile_image) : client.url(`images/user-profile/${user_details?.profile_image}`) : "";

    const generatePassword = () => {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*(){}?/][",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const UserDetailsSubmit = (values) => {
        const postData = {
            fname: values.fname,
            lname: values.lname,
            email: values.email,
            mobile: values.mobile,
            checkEmail: values.checkEmail,
            user_id: user_details._id
        };
        client.post(api.userDetailsSave, postData, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success(" Updated Successfully.");
                            window.location.reload();
                        } else {
                            toast.error(response.message);
                            setFormData({
                                fname: user_details?.FirstName || user_details?.name.split(" ")[0],
                                lname: user_details?.LastName || user_details?.name.split(" ")[1],
                                email: user_details?.email || ""
                            })
                        }
                    } catch (error) {
                        toast.error("Internal error occured.Please contact support.");
                    }
                } else {
                    toast.error("Internal error occured.Please contact support.");
                }
            } else {
                toast.error("Internal error occured.Please contact support.");
            }
        });
    };

    useEffect(() => {

        try {
            setcomments(user_details.comments)
        } catch {

        }

    }, [user_details])

    const render_date = (add_date) => {
        if (typeof add_date === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    };


    const onSubmitTempPassword = (event) => {
        event.preventDefault();

        if (temp_password_reason) {

            const temp_password = generatePassword();
            setpassword(temp_password)
            let adminUser = JSON.parse(localStorage.getItem("user"))

            const postData = {
                user_id: user_details?._id,
                temp_password: {
                    password: temp_password,
                    generated_by: localStorage.getItem("userId"),
                    temp_password_reason: temp_password_reason
                },
                // comment: { type: `Tempoarary password generated`, note: temp_password_reason, user: adminUser.full_name }
            }

            // console.log(postData);
            client.post(api.generate_temp_password, postData, (error, response) => {
                if (!error) {
                    if (!response.error) {
                        settemp_password_reason("")
                        // console.log("success");
                        let a = comments;
                        a.push({
                            type: "temp_password",
                            user: adminUser.full_name,
                            date: new Date(),
                            note: temp_password_reason
                        })
                        setcomments(a)
                        setgeneratePasswordModal(true)


                        // this.setState({ generatePasswordModal: true })
                    } else {
                        toast.error(response.message);
                    }
                } else {
                    toast.error("Internal error occured.Please contact support.");
                }
            })
        }
    }

    const renderGeneratePasswordModal = () => {
        return (
            <Modal isOpen={generatePasswordModal} toggle={() => setgeneratePasswordModal(false)} className="modal-body" centered={true}>
                <form className="theme-form" >
                    <ModalBody style={{ textAlign: "center" }}>
                        <h5>Super Password</h5>
                        <h4 id="temp-pw">{password}</h4>
                        <p style={{ color: "red" }}>* valid for 10 minutes only</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button type='button' color="secondary" onClick={() => setgeneratePasswordModal(false)}>Cancel</Button>

                        <CopyToClipboard text={`${password}`} onCopy={() => settextCopy(true)}>
                            <button className="btn btn-primary" type="button" id="link-copy">Copy to clipboard</button>
                        </CopyToClipboard>
                        <Tooltip placement="bottom" target="link-copy" isOpen={textCopy}>
                            Copied!
                        </Tooltip>

                    </ModalFooter>
                </form>
            </Modal>)
    }

    const renderComment = (getCommentData) => {

        const reversed = [...getCommentData].reverse();

        return (
            <>
                {
                    reversed.map((el) => {
                        return (
                            <li style={{ margin: "0" }}>
                                <div
                                    className="message my-message "
                                    style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}
                                >
                                    <p>
                                        {el.user}
                                        <span className="message-data-time pull-right">{render_date(el.date)}</span>{" "}
                                    </p>
                                    {/* {el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>} */}
                                    {el.note}
                                </div>
                            </li>
                        );
                    })
                }
            </>
        );
    }
    // console.log(user_details, "user")


    const CustomInput = ({
        className,
        value,
        type,
        style,
        autoComplete,
        onChange,
        onClickIcon,
        name,
        ref,
        ...props }) => {

        return (
            <>
                <div className="form__form-group-field position-relative">
                    <input
                        {...props}
                        className={className}
                        value={value}
                        type={type}
                        style={style}
                        onChange={onChange}
                        name={name}
                        ref={ref}
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="off"
                    />
                    <div
                        className='d-flex align-items-center'
                        onClick={onClickIcon}
                        style={{ height: "32px", position: "absolute", top: "1px", right: "6px", border: "none", backgroundColor: "#fff" }}
                    >
                        {type == "text" ? <EyeOffIcon style={{ height: "18px", width: "18px", fill: "#999999", }} /> : <EyeIcon style={{ height: "18px", width: "18px", fill: "#999999", }} />}
                    </div>
                </div>
            </>
        );
    };


    return (
        <Fragment>
            {renderGeneratePasswordModal()}
            <div className="container-fluid">

                <div className="edit-profile">
                    <div className="row">
                        <div className="col-md-9" style={{ paddingRight: "0", paddingLeft: "7.5px" }}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Account Details</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body" style={{ paddingBottom: "15px" }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group row">
                                                <label className="form-label col-md-3">First Name : </label>
                                                <div className='col-md-9'>
                                                    <input
                                                        className="form-control"
                                                        onBlur={event => {
                                                            let firstname = event.target.value;
                                                            if (!firstname && firstname.length == 0) {
                                                                toast.error("This field shouldn't be empty", { autoClose: 2000 });
                                                                setFormData({ ...formData, fname: user_details?.FirstName })
                                                            } else if (firstname !== user_details?.FirstName) {
                                                                UserDetailsSubmit({ fname: firstname, lname: user_details?.LastName });
                                                            }
                                                        }}
                                                        onChange={(e) => setFormData({ ...formData, fname: e.target.value })}
                                                        type="text"
                                                        value={formData?.fname}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group row">
                                                <label className="form-label col-md-3">Last Name : </label>
                                                <div className='col-md-9'>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        onBlur={event => {
                                                            let lastname = event.target.value;
                                                            if (lastname !== user_details?.LastName) {
                                                                UserDetailsSubmit({ fname: user_details?.FirstName, lname: lastname });
                                                            }
                                                        }}
                                                        onChange={(e) => setFormData({ ...formData, lname: e.target.value })}
                                                        value={formData?.lname}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group row">
                                                <label className="form-label col-md-3">E-mail : </label>
                                                <div className='col-md-9 d-flex'>
                                                    <input
                                                        className="form-control w-50"
                                                        type="text"
                                                        onBlur={event => {
                                                            let email = event.target.value;
                                                            if (email !== user_details?.email) {
                                                                UserDetailsSubmit({ email, checkEmail: true });
                                                            }
                                                        }}
                                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                        value={formData?.email}
                                                    />
                                                    {user_details?.email_verified_date ? <div className='ml-3 p-2 w-50' style={{ border: "1px solid rgb(214,233,198)", backgroundColor: "rgb(223,240,216)", borderRadius: "0.25rem" }}>
                                                        Verified on: {moment(user_details?.email_verified_date).format("hh:mm:ss A ddd DD/MM/YYYY")}
                                                    </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group row">
                                                <label className="form-label col-md-3">Mobile No :</label>
                                                <div className='col-md-9'>
                                                    <input className="form-control" disabled={true} readOnly type="text" value={user_details?.mobile} />
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-12">
                                            <div className="form-group row">
                                                <label className="form-label col-md-3">User Name :</label>
                                                <div className='col-md-9'>
                                                    <input className="form-control" disabled={true} readOnly type="text" value={user_details?.userName} />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3" style={{ paddingRight: "0", paddingLeft: "7.5px" }}>
                            <div className="card">

                                <div className="card-body" style={{ paddingBottom: "15px" }}>
                                    <div className="profile_box px-0 ds_v" style={{
                                        maxHeight: '12rem',
                                        maxWidth: '12rem',
                                        width: "12rem",
                                        height: "12rem",
                                        overflow: 'hidden',
                                    }}>
                                        <div className='' style={{ padding: "10px", backgroundColor: "#E8E8E8" }}>
                                            <div className='' style={{ backgroundColor: "white", border: "1px solid #DCDCDC" }}>
                                                <RenderDropZoneField
                                                    image_url={image_url}
                                                    id={user_details?._id}
                                                    // setImageLoader={setImageLoader}
                                                    // imageLoader={imageLoader}
                                                    input={{
                                                        name: "abc",
                                                        onChange: (data, { file }) => {
                                                            uploadProfile(data, { file })
                                                        }
                                                    }}
                                                />
                                                {/* <aside className="dropzone__img">
                                                    {image_url ?
                                                        <img src={`${image_url}?${Date.now()}`} className="p-0" alt="drop-img" />
                                                        :
                                                        <img src={`${no_image}`} className="p-0" alt="drop-img" />
                                                    }
                                                </aside> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* <hr className="divider--separate divider--small--separate" style={{ margin: "3rem 1rem", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }} /> */}

                    {!merchant_info.is_trashed &&
                        <div className="row mb-4" style={{ boxShadow: '1px 5px 24px 0 rgba(68, 102, 242, 0.05)', padding: "1.5rem 0rem" }}>
                            <div className="col-sm-12 col-md-4 col-lg-4 d-flex justify-content-center " style={{ borderRight: "1px solid gainsboro" }}>
                                <MerchantUserPasswords
                                    user_details={user_details}
                                    merchant_id={merchant_id}
                                    merchant_info={merchant_info}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 d-flex justify-content-center" style={{ borderRight: "1px solid gainsboro" }}>
                                <MerchantUserTransactionPin
                                    user_details={user_details}
                                    merchant_id={merchant_id}
                                    merchant_info={merchant_info}
                                    pin_type={1}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 d-flex justify-content-center " style={{}}>
                                <MerchantUserPins
                                    user_details={user_details}
                                    merchant_id={merchant_id}
                                    merchant_info={merchant_info}
                                    pin_type={2}
                                />
                            </div>
                        </div>
                    }

                    {/* <hr className="divider--separate divider--small--separate" style={{ margin: "3rem 1rem", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }} /> */}
                    {!merchant_info.is_trashed &&

                        <div className="row">
                            <div className="col-md-6" style={{ paddingRight: "0", paddingLeft: "7.5px" }}>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title mb-0">Generate temporary password for merchant</h4>
                                        <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                    </div>
                                    <div className="card-body" style={{ paddingBottom: "15px" }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <form className="theme-form" onSubmit={onSubmitTempPassword}>
                                                    <div className="form-group row">
                                                        <label className="form-label col-md-12">Enter reason for your action</label>
                                                        <div className='col-md-12'>
                                                            <input required className="form-control" type="text" name="reason" value={temp_password_reason} onChange={(event) => settemp_password_reason(event.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-row mb-0 pull-right">
                                                        <button className="btn btn-primary btn-xs" type="submit">Generate</button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ paddingRight: "0", paddingLeft: "7.5px" }}>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title mb-0">Password generated history</h4>
                                        <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                    </div>
                                    <div className="card-body" style={{ paddingBottom: "15px" }}>
                                        <div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
                                            <div className="chat-right-aside bitcoin-chat">
                                                <div className="chat">
                                                    <div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
                                                        <ul style={{ margin: "0" }}>
                                                            {
                                                                user_details?.comments?.length > 0 ?
                                                                    renderComment(user_details?.comments)
                                                                    : (
                                                                        <p className='text-center'>No comments!</p>
                                                                    )
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }

                </div>
            </div>
        </Fragment>
    )
}
