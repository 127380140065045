import React, { useState, useEffect, Fragment, ReactDOM } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Search } from "react-feather";
import "../../../table.css";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab, UncontrolledTooltip, ButtonToolbar } from "reactstrap";
import { Link as NavLink } from "react-router-dom";

const SearchBar = () => {

	let searchInput = React.createRef();

	const [data, setData] = useState(null);
	const [searchData, setSearchData] = useState(null);
	const [searchOpen, setSearchOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [searchResult, setSearchResult] = useState([]);
	const [searchFilter, setSearchFilter] = useState("Merchant Name:");
	const [searchFilterCode, setSearchFilterCode] = useState("MERCHANT_NAME");
	const [invitedUsers, setInvitedUsers] = useState([]);

	const handleClickOutside = (event) => {

		if (event.target.className != "disable-close-action") {
			closingSearchbar();
		}
	}

	useEffect(() => {

		document.addEventListener('click', handleClickOutside, true);
		return () => { document.removeEventListener('click', handleClickOutside, true) };

	}, []);


	useEffect(() => {

		try {
			document.querySelector(".searchIcon").value = searchValue;
		} catch (error) {
			return;
		}

	}, [searchValue])

	const toggleBtn = () => {

		if (!searchOpen) {
			setSearchOpen(true);
		}

		// document.querySelector('.searchIcon').classList.remove('open');
		document.querySelector(".table").classList.remove("is-open");
		document.querySelector(".transaction").classList.remove("is-open");
		document.querySelector(".filter").classList.add("is-open");
		document.body.classList.add("offcanvas");
		document.querySelector(".page-body").addEventListener("click", () => {
			closingSearchbar();
		});
		// }
		/*  else {
			 this.setState(prevState => {
				 return {
					 ...prevState,
					 searchOpen: !prevState.searchOpen,
					 searchValue: "",
					 searchFilter: null
				 }
			 })
			 document.querySelector('.searchIcon').classList.remove('open');
			 document.querySelector(".empty").classList.remove('is-open');
			 document.querySelector(".table").classList.remove('is-open');
			 document.querySelector(".filter").classList.remove('is-open');
			 document.querySelector(".transaction").classList.remove('is-open');
			 document.querySelector(".searchIcon").blur();
			 document.querySelector(".searchIcon").value = "";
			 document.body.classList.remove("offcanvas");
		 } */
	};


	const onFilterSelect = (code, text) => {

		setSearchFilterCode(code);
		setSearchFilter(text);
		setSearchData([]);

		document.querySelector(".filter").classList.remove("is-open");
		searchInput.current.focus();

	};

	const closingSearchbar = () => {
		setSearchOpen(false);
		// document.querySelector('.searchIcon').classList.remove('open');
		// try {
		document.querySelector(".table").classList.remove("is-open");
		document.querySelector(".filter").classList.remove("is-open");
		document.querySelector(".transaction").classList.remove("is-open");
		document.querySelector(".searchIcon").blur();
		document.querySelector(".searchIcon").value = "";
		document.body.classList.remove("offcanvas");
		if (document.querySelector(".empty") !== null)
			document.querySelector(".empty").classList.remove("is-open");
		// } catch {

		// }
		// document.querySelector(".select-filter").classList.remove("is-open");
	};

	const onSearchChangeHandler = () => {

		let actionclass = ".table";

		if (searchValue) {

			let postdata = { searchFilterCode, term: searchValue };

			if (searchFilterCode == "TRANSACTION_ID") {
				actionclass = ".transaction";
			}

			client.get(api.mastersearch, postdata, (error, response) => {
				// console.log(response, "searchbar response")
				if (!error) {
					try {
						if (!response.error) {

							setSearchResult(response.result.data);
							console.log(response.result.data)
							setInvitedUsers(response.result.invited_users_data)

							if (response.result.data.length > 0 || response.result.invited_users_data.length > 0) {
								document.querySelector(actionclass).classList.add("is-open");
							} else {
								document.querySelector(".empty").classList.add("is-open");
							}

						} else {
							setTimeout(() => { toast.error(response.message) }, 200);
							document.querySelector(actionclass).classList.remove("is-open");
						}
					} catch (e) {
						// console.log(e, "fetch error")
						setTimeout(() => { toast.error("Internal error occured. Please contact support 1") }, 200);
						document.querySelector(actionclass).classList.remove("is-open");
					}
				} else {
					setTimeout(() => { toast.error("Internal error occured. Please contact support 2") }, 200);
					document.querySelector(actionclass).classList.remove("is-open");
				}
				//   current.setState({ loading: false });
			});
		} else {
			setSearchResult([]);
			setInvitedUsers([])
			document.querySelector(actionclass).classList.remove("is-open");

		}
	}


	useEffect(() => {

		const getData = setTimeout(() => { onSearchChangeHandler() }, 1000);

		return () => clearTimeout(getData)

	}, [searchValue])


	const getIpayId = (id) => {
		// const found = this.state.data.find((el) => {
		// 	return el._id == id;
		// });
		// if (found) {
		// 	return found.ipay_id;
		// }
		// return;
	};

	const getStatus = (status) => {
		if (status == 1) {
			return "Success";
		} else {
			return "Failed";
		}
	};

	const getAccountDetials = (item, type) => {
		if (type == "from") {
			if (item.from_customers && item.from_customers.length > 0) {
				return item.from_customers[0].ipay_id

			} else if (item.from_mechant && item.from_mechant.length > 0) {
				return item.from_mechant[0].ipay_id
			} else {
				return "";
			}
		} else {
			if (item.to_customers && item.to_customers.length > 0) {
				return item.to_customers[0].ipay_id

			} else if (item.to_mechant && item.to_mechant.length > 0) {
				return item.to_mechant[0].ipay_id
			} else {
				return "";
			}
		}
	}

	return (
		<Fragment>
			{true ? (
				<div>
					<form
						className="form-inline search-form"
						onSubmit={(event) => {
							event.preventDefault();
							// onSearchChangeHandler(event)
						}}
					>
						<div className="form-group" style={{ position: "relative" }}>
							<div className="searchIcon"
								style={{
									width: '100%',
									background: '#fff',
									borderRadius: '3px',
									height: '42px',
									display: 'flex',
								}}
							>
								{/* {document.activeElement === this.searchInput?.current && */}
								<div onClick={toggleBtn}
									style={{
										width: '140px',
										borderRight: '2px solid #dde2f1',
										color: '#9fa3b1',
										position: 'relative',
										cursor: 'pointer',
										textAlign: 'center',
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<span
										class="disable-close-action"
										onClick={toggleBtn}
										style={{ cursor: 'pointer', width: '160px' }}>{searchFilter}
									</span>
								</div>

								<div>
									<input
										className=""
										ref={searchInput}
										type="text"
										style={{ paddingLeft: 15, width: '100%' }}
										placeholder="search"
										onClick={closingSearchbar}
										defaultValue={searchValue}
										onChange={(event) => { setSearchValue(event.target.value) }}
									//onKeyDown={handleKeyDown}
									/>
								</div>
							</div>
							{/* <span>{searchFilter}</span> */}
							<div
								className="Typeahead-menu filter"
								style={{ maxHeight: "380px" }}
								id="search-outer"
							>
								<div>
									<div style={{ textAlign: "left" }}>
										<h6>SUGGESTED FILTERS</h6>
										<div
											className={`search-filter__line ${searchFilter == "Merchant Name:" ? "search-filter__active" : " "}`}
											onClick={() => onFilterSelect("MERCHANT_NAME", "Merchant Name:")}
										>
											<span className="search-filter">Merchant Name:</span>specify merchant name
										</div>
										<div
											className={`search-filter__line ${searchFilter == "Customer Name:" ? "search-filter__active" : ""}`}
											onClick={() => onFilterSelect("CUSTOMER_NAME", "Customer Name:")}
										>
											<span className="search-filter">Customer Name:</span>specify customer name
										</div>
										<div
											className={`search-filter__line ${searchFilter == "Mobile No:" ? "search-filter__active" : ""}`}
											onClick={() => onFilterSelect("MOBILE_NO", "Mobile No:")}
										>
											<span className="search-filter">Mobile No:</span>specific mobile number
										</div>
										<div
											className={`search-filter__line ${searchFilter == "Email Id:" ? "search-filter__active" : ""}`}
											onClick={() => onFilterSelect("EMAIL_ID", "Email Id:")}
										>
											<span className="search-filter">Email Id:</span>an email address
										</div>
										<div
											className={`search-filter__line ${searchFilter == "iPayPro Id:" ? "search-filter__active" : ""}`}
											onClick={() => onFilterSelect("IPAYPRO_ID", "iPayPro Id:")}
										>
											<span className="search-filter">iPayPro Id:</span>specific i-Pay id
										</div>
										<div
											className={`search-filter__line ${searchFilter == "Transaction Id:" ? "search-filter__active" : ""}`}
											onClick={() => onFilterSelect("TRANSACTION_ID", "Transaction id:")}
										>
											<span className="search-filter">Transaction id:</span>specific transaction id
										</div>
										<div
											className={`search-filter__line ${searchFilter == "Referal code:" ? "search-filter__active" : ""}`}
											onClick={() => onFilterSelect("RFFERAL_CODE", "Referal code:")}
										>
											<span className="search-filter">Referal code:</span>specific Referal code
										</div>

										<div
											className={`search-filter__line ${searchFilter == "Minisite:" ? "search-filter__active" : ""}`}
											onClick={() => onFilterSelect("MINISITE", "Minisite:")}
										>
											<span className="search-filter">Minisite:</span>specific mini site
										</div>
									</div>
								</div>
							</div>

							<div className="Typeahead-menu table" style={{ position: "absolute", width: "800px", maxWidth: "unset" }}>
								{searchFilterCode != "TRANSACTION_ID" &&
									<div
										className="table-responsive"
										style={{ textAlign: "left" }}
									>
										<table className="table table-border-horizontal table-striped">
											<thead>
												<tr style={{ padding: "11px 0" }}>
													<th scope="col" style={{ width: "12%" }}>
														More Info
													</th>
													<th scope="col" style={{ width: "20%" }}>
														i-Pay ID
													</th>
													<th scope="col" style={{ width: "20%" }}>
														Name
													</th>
													<th scope="col" style={{ width: "23%" }}>
														Minisite/Email
													</th>
													<th scope="col" style={{ width: "17%" }}>
														Mobile
													</th>
													{/* <th scope="col" style={{ width: "14%" }}>Wallet balance</th> */}

												</tr>
											</thead>
											<tbody>
												{searchResult.length > 0 &&
													searchResult.map((res) => {
														return (
															<tr style={{ padding: "11px 0" }} key={res._id}>
																<td style={{ width: "12%" }}>
																	<a
																		href={
																			res.business_name
																				? `${process.env.PUBLIC_URL}/merchants/merchantDetails/${res._id}`
																				: `${process.env.PUBLIC_URL}/customer/wallet/${res._id}`
																		}
																	>
																		<i className="icofont icofont-info"></i>
																	</a>
																</td>
																<td style={{ width: "20%" }}>{res.ipay_id}</td>
																<td style={{ width: "20%" }}> {res.business_name ? res.business_name : res.name} {res.unique_id ? <span style={{ fontSize: "14px", fontWeight: "600" }}>(Owner)</span> : null} </td>
																<td style={{ width: "23%" }}>

																	{res.unique_id ? `${(res?.business_sub_category?.trim().toLowerCase() == "adult entertainment" || res?.business_sub_category?.trim().toLowerCase() == "hustlers") ? "hustlerz.co/" + res.unique_id : "linky.biz/" + res.unique_id}` : res?.linkyURL ? res.linkyURL : ""}
																	{res.unique_id ? <>/<br /></> : <></>}

																	{/* {tab != 1 || tab != 2 ? res.email.replace(/(.{23})/g, '$1\n') : res.email} */}
																	{res?.email ? <>{res.email}
																		{res.unique_id ? <span style={{ fontSize: "14px", fontWeight: "600" }}>(Store Email)</span> : null}</> : ''}
																	{res?.email ? <><br /></> : <></>}
																	{res?.userData?.email ? <>{res.userData.email} <span style={{ fontSize: "14px", fontWeight: "600" }}>(User Account Email)</span></> : ''}
																</td>
																<td style={{ width: "17%" }}>{res.mobile}</td>
																{/* <td style={{ width: "14%" }}>$0</td> */}

															</tr>
														);
													})
													// :
													// <tr style={{ padding: "11px 0" }}>
													// 	<td colSpan={5}>No data found</td>
													// </tr>
												}

												{
													invitedUsers.length > 0 && invitedUsers.map((res) => {
														return (
															<tr style={{ padding: "11px 0" }} key={res._id}>
																<td style={{ width: "12%" }}>
																	<NavLink to={`/merchants/inviteUserDetails/${res._id}`} item={res}>
																		<i className="icofont icofont-info"></i>
																	</NavLink>
																</td>
																<td style={{ width: "20%" }}>{"N/A"}</td>
																<td style={{ width: "20%" }}> {res.name} <span style={{ fontSize: "14px", fontWeight: "600" }}>(User)</span></td>
																<td style={{ width: "23%" }}>
																	<a href={'https://' + (res?.unique_id ? `${res?.brand_ID == 1 ? "ipaypro.co/" + res.unique_id : res?.brand_ID == 2 ? "linky.biz/" + res.unique_id : res?.brand_ID == 3 ? "hustlerz.co/" + res.unique_id : "No Data /" + res.unique_id}` : res?.linkyURL ? res.linkyURL : "Skipped")}
																		className={(res?.unique_id ? `${res?.brand_ID == 1 ? "ipaypro.co/" + res.unique_id : res?.brand_ID == 2 ? "linky.biz/" + res.unique_id : res?.brand_ID == 3 ? "hustlerz.co/" + res.unique_id : "No Data /" + res.unique_id}` : res?.linkyURL ? "resLink" : "SkippedLink")}
																		target='_blank'>
																		{res?.unique_id ? `${res?.brand_ID == 1 ? "ipaypro.co/" + res.unique_id : res?.brand_ID == 2 ? "linky.biz/" + res.unique_id : res?.brand_ID == 3 ? "hustlerz.co/" + res.unique_id : "No Data /" + res.unique_id}` : res?.linkyURL ? res.linkyURL : "Skipped"}/
																	</a>
																	<br />
																	{res?.email}
																</td>
																<td style={{ width: "17%" }}>{res.mobile}</td>
															</tr>
														);
													})
													// :
													// <tr style={{ padding: "11px 0" }}>
													// 	<td colSpan={5}>No data found</td>
													// </tr>
												}
											</tbody>
										</table>
									</div>
								}
							</div>

							<div className="Typeahead-menu transaction table" style={{ position: "absolute", width: "800px", maxWidth: "unset" }}>
								{searchFilterCode == "TRANSACTION_ID" &&

									<div
										className="table-responsive"
										style={{ textAlign: "left" }}
									>
										<table className="table table-border-horizontal table-striped">
											<thead>
												<tr style={{ padding: "11px 0" }}>
													<th scope="col" style={{ width: "20%" }}>
														Transaction ID
													</th>
													<th scope="col" style={{ width: "20%" }}>
														From
													</th>
													<th scope="col" style={{ width: "20%" }}>
														To
													</th>
													<th scope="col" style={{ width: "11%" }}>
														Amount
													</th>
													<th scope="col" style={{ width: "12%" }}>
														Status
													</th>
													<th scope="col" style={{ width: "12%" }}>
														More Info
													</th>
												</tr>
											</thead>
											<tbody>
												{searchResult != null &&
													searchResult.map((res) => {
														return (
															<tr style={{ padding: "11px 0" }} key={res._id}>
																<td style={{ width: "20%" }}>
																	{res.transaction_no}
																</td>
																<td style={{ width: "20%" }}>
																	{getAccountDetials(res, "from")}
																</td>
																<td style={{ width: "20%" }}>
																	{getAccountDetials(res, "to")}
																</td>
																<td style={{ width: "11%" }}>${res.amount}</td>
																<td style={{ width: "12%" }}>
																	{getStatus(res.status)}
																</td>
																<td style={{ width: "12%" }}>
																	<a href="#">
																		<i className="icofont icofont-info"></i>
																	</a>
																</td>
															</tr>
														);
													})}
											</tbody>
										</table>
									</div>
								}
							</div>
							{
								(searchResult.length == 0 && invitedUsers.length == 0) &&
								<div className="Typeahead-menu empty" style={{ position: "absolute", width: "800px", maxWidth: "unset" }}>
									<div style={{ textAlign: "center", marginTop: "30px" }}>
										<div style={{ fontSize: "3.5rem" }}>
											<i className="icofont icofont-ui-search"></i>
										</div>
										<div>
											<h5>No search results found</h5>
											<p>Please try again with a different search query</p>
										</div>
									</div>
								</div>
							}

						</div>
					</form>
				</div>
			) : null}
		</Fragment>
	);

}

export default SearchBar;
